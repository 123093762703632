import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        tableConfig: {
            type: Object,
            default: {
                '#datatable': {
                    autoWidth: true,
                    columns: [
                        null,  // Name
                        null,  // Email
                        null,  // Role
                        null,  // Auth Method
                        null,  // Signed in
                        { orderable: false }  // Actions
                    ],
                    order: [[0, 'asc']],
                    pageLength: 10,
                    language: {
                        emptyTable: "No team members found"
                    }
                },
                '#datasharing': {
                    autoWidth: true,
                    columns: [
                        null,  // Account Number
                        null,  // Name
                        null,  // Organization Jurisdiction
                        null,  // Storage Jurisdiction
                        null,  // Direction
                        { orderable: false }  // Actions
                    ],
                    order: [[0, 'asc']],
                    pageLength: 10,
                    language: {
                        emptyTable: "No data sharing agreements found"
                    }
                }
            }
        }
    }

    initialize() {
        // Keep track of initialized tables
        this.initializedTables = new Set();
    }

    connect() {
        this.initializeTables();
        this.initializePopovers();
    }

    initializeTables() {
        const configs = this.tableConfigValue;

        Object.entries(configs).forEach(([tableId, config]) => {
            const table = document.querySelector(`table${tableId}`);

            if (table) {
                // Check if table is already initialized
                if (this.isTableInitialized(table)) {
                    console.log(`Table ${tableId} already initialized, destroying first`);
                    this.destroyTable(table);
                }

                console.log(`Initializing table: ${tableId}`);
                new DataTable(table, config);
                this.initializedTables.add(tableId);
            }
        });
    }

    isTableInitialized(table) {
        try {
            return DataTable.getInstance(table) !== null;
        } catch (e) {
            return false;
        }
    }

    destroyTable(table) {
        try {
            const dt = DataTable.getInstance(table);
            if (dt) {
                dt.destroy();
            }
        } catch (e) {
            console.error('Error destroying DataTable:', e);
        }
    }

    initializePopovers() {
        const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
        popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl)
        });
    }

    disconnect() {
        // Clean up all initialized tables
        this.initializedTables.forEach(tableId => {
            const table = document.querySelector(`table${tableId}`);
            if (table) {
                this.destroyTable(table);
            }
        });
        this.initializedTables.clear();
    }
}