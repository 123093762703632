import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["aliasInput"]
    static values = {
        checkPath: String
    }

    connect() {
        const controllerParams = JSON.parse(this.data.get("params"));
        this.setupCountryChange(controllerParams);
    }

    setupCountryChange(controllerParams) {
        $(document).ready(() => {
            $('#organization_account_country_id').on('change', function() {
                var selectedCountryId = $(this).val();

                $.ajax({
                    url: controllerParams['dashboardBillingContactIndexPath'] + '&country_id=' + selectedCountryId,
                    method: 'GET',
                    success: function(response) {
                        $('#regnumber').text(response.company_id);
                        $('#taxnumber').text(response.tax_id_company);
                        $('#eu_taxnumber').text(response.tax_id_company);

                        response.sanctions ? $(".sanction-alert").show() : $(".sanction-alert").hide();

                        if (response.eu_vat) {
                            $("#eu-vat").show();
                            $("#other-vat").hide();
                            let countryCode = response.country_code === "GR" ? "EL" : response.country_code;
                            $("#organization_account_business_eu_vat_no").attr('placeholder', `${countryCode}00000000000000`);
                        } else {
                            $("#eu-vat").hide();
                            $("#other-vat").show();
                        }
                    },
                    error: function() {
                        $('#countryInfo').text('Failed to fetch country info.');
                    }
                });
            });

            $('#organization_account_country_id').val(controllerParams['defaultCountryId']).trigger('change');
        });
    }

    validateAlias(event) {
        clearTimeout(this.timeout);
        const alias = event.target.value.trim();

        if (!alias) {
            this.clearValidation();
            return;
        }

        this.timeout = setTimeout(() => {
            $.ajax({
                url: this.checkPathValue,
                method: 'GET',
                data: { alias, do_what: 'check_alias' },
                success: (response) => this.handleValidationResponse(response),
                error: () => this.handleError()
            });
        }, 300);
    }

    clearValidation() {
        const input = this.aliasInputTarget;
        input.classList.remove('is-invalid', 'is-valid');
        $(input).siblings('.invalid-feedback-taken, .invalid-feedback-invalid, .valid-feedback').hide();
    }

    handleValidationResponse(response) {
        this.clearValidation();
        const input = this.aliasInputTarget;
        const inputWrapper = $(input);

        switch(response.status) {
            case 'available':
                input.classList.add('is-valid');
                inputWrapper.siblings('.valid-feedback').show();
                break;
            case 'taken':
                input.classList.add('is-invalid');
                inputWrapper.siblings('.invalid-feedback-taken').show();
                break;
            case 'not_valid':
                input.classList.add('is-invalid');
                inputWrapper.siblings('.invalid-feedback-invalid').show();
                break;
        }
    }

    handleError() {
        const input = this.aliasInputTarget;
        input.classList.add('is-invalid');
        $(input).siblings('.invalid-feedback-invalid').show();
    }
}