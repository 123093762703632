import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["iconPlaceholder", "logoPlaceholder"]

  connect() {
    this.initializeColorPickers()
  }

  initializeColorPickers() {
    document.querySelectorAll('.color-box').forEach(box => {
      const colorInput = box.querySelector('input[type="color"]')
      const textInput = box.querySelector('input[type="text"]')

      colorInput.addEventListener('input', (e) => {
        textInput.value = e.target.value
      })

      textInput.addEventListener('input', (e) => {
        const value = e.target.value
        if (/^#[0-9A-F]{6}$/i.test(value)) {
          colorInput.value = value
        }
      })
    })
  }

  previewIcon(event) {
    this.handleFileUpload(event, "icon")
  }

  previewLogo(event) {
    this.handleFileUpload(event, "logo")
  }

  handleFileUpload(event, type) {
    const file = event.target.files[0]
    const maxSize = parseInt(event.target.dataset.maxFileSize)

    if (!file) return

    // Check file size
    if (file.size > maxSize) {
      alert(`File size must be less than ${maxSize / 1024}KB`)
      event.target.value = ""
      return
    }

    // Check file type
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      alert('Only JPG and PNG files are allowed')
      event.target.value = ""
      return
    }

    // Preview image
    const reader = new FileReader()
    const previewImage = event.target.nextElementSibling
    const placeholder = type === 'icon' ? this.iconPlaceholderTarget : this.logoPlaceholderTarget

    reader.onload = (e) => {
      previewImage.src = e.target.result
      previewImage.style.display = 'block'
      placeholder.style.display = 'none'
    }

    reader.readAsDataURL(file)
  }
}