// app/javascript/controllers/chat_scroll_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        this.observer = new MutationObserver(() => {
            // Wait for the DOM to fully update
            setTimeout(() => {
                this.scrollToBottom()
                this.focusInput()
            }, 100)  // Small delay to ensure rendering is complete
        })
        this.observer.observe(this.element, { childList: true, subtree: true })
        this.scrollToBottom()
    }

    disconnect() {
        if (this.observer) this.observer.disconnect()
    }

    scrollToBottom() {
        const container = this.element.closest('.messages-container')
        if (container) {
            container.scrollTo({
                top: container.scrollHeight,
                behavior: 'smooth'
            })
        }
    }

    focusInput() {
        // Find the input field
        const input = document.getElementById('ai_assistant_input_ai_assistant_input_chat')
        if (input) {
            input.focus()
            // Optionally move cursor to end of input
            const length = input.value.length
            input.setSelectionRange(length, length)
        }
    }
}