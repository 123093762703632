import "@hotwired/turbo-rails"
import "@hotwired/stimulus"

import "~/jquery"
import "~/controllers"
import "~/dashboard"
import "~/menu"
import "~/bynntoast"

import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap;

import "@ckeditor/ckeditor5-build-classic"
import "@curiosityx/bootstrap-session-timeout"
import "@simonwep/pickr"

import "alertifyjs"
import "apexcharts"
import "chance"
import "chart.js"

import feather from "feather-icons"
import "flatpickr"
import "fullcalendar"
import "glightbox"
import "imask"

import "jquery-countdown"
import "jquery-knob"
import "jquery-sparkline"
import "jsvectormap"
import "jszip"

import "leaflet"
import "masonry-layout"
import "metismenu"
import "nouislider"

//import "pace-js"
import "pdfmake"
import "pristinejs"
import "simplebar"
import "sweetalert2"
import "swiper"
import "twitter-bootstrap-wizard"
import toastr from "toastr"
window.toastr = toastr;

import "vendor/admin-resources/bootstrap-filestyle/bootstrap-filestyle.min.js"
import "vendor/table-edits/build/table-edits.min.js"
import "wnumb"

document.addEventListener('turbo:load', function() {
  document.body.setAttribute('data-sidebar-size', 'sm');
  feather.replace();
});



