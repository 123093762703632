// toast.js
class Toast {
    constructor() {
        this.timeoutId = null;
        this.container = null;
    }

    getIconSVG(type) {
        switch (type) {
            case 'success':
                return `<svg class="toast-icon success" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
          <polyline points="22 4 12 14.01 9 11.01"></polyline>
        </svg>`;
            case 'loading':
                return `<svg class="toast-icon loading" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <line x1="12" y1="2" x2="12" y2="6"></line>
          <line x1="12" y1="18" x2="12" y2="22"></line>
          <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
          <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
          <line x1="2" y1="12" x2="6" y2="12"></line>
          <line x1="18" y1="12" x2="22" y2="12"></line>
          <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
          <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
        </svg>`;
            case 'error':
                return `<svg class="toast-icon error" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="15" y1="9" x2="9" y2="15"></line>
          <line x1="9" y1="9" x2="15" y2="15"></line>
        </svg>`;
            default:
                return '';
        }
    }

    show({
             message = 'Operation complete',
             subMessage = '1 successful, 0 failed',
             status = 'success',
             duration = 3000,
             actionButton = 'Ok'
         } = {}) {
        // Clear any existing toasts
        this.hide();

        // Create wrapper if it doesn't exist
        const wrapper = document.createElement('div');
        wrapper.className = 'bynn-toast';

        // Create container
        this.container = document.createElement('div');
        this.container.className = 'toast-container toast-fade-in';

        // Set button text based on status
        let buttonText = actionButton;
        let buttonHtml = '';

        if (status === 'loading') {
            buttonText = 'Cancel';
            buttonHtml = `<button class="toast-button">${buttonText}</button>`;
        } else if (status === 'error') {
            buttonText = 'Try Again';
            buttonHtml = `<button class="toast-button">${buttonText}</button>`;
        }

        this.container.innerHTML = `
      <div class="toast">
        ${this.getIconSVG(status)}
        <div class="toast-content">
          <p class="toast-title">${message}</p>
          <p class="toast-subtitle">${subMessage}</p>
        </div>
        ${buttonHtml}
      </div>
    `;

        wrapper.appendChild(this.container);
        document.body.appendChild(wrapper);

        // Add click handler to button only if button exists
        const button = this.container.querySelector('.toast-button');
        if (button) {
            button.addEventListener('click', () => this.hide());
        }

        // Auto-hide for success status
        if (status === 'success' && duration) {
            this.timeoutId = setTimeout(() => {
                this.hide();
            }, duration);
        }
    }

    hide() {
        const toasts = document.querySelectorAll('.bynn-toast');
        toasts.forEach(toast => {
            const container = toast.querySelector('.toast-container');
            if (container) {
                container.classList.remove('toast-fade-in');
                container.classList.add('toast-fade-out');

                setTimeout(() => {
                    if (toast && toast.parentNode) {
                        toast.parentNode.removeChild(toast);
                    }
                }, 300);
            }
        });

        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
    }
}

// Create a global toast instance
window.toast = new Toast();