import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {

        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl)
        })


        var frame = document.querySelector("#AMLList");
        var url = `aml/turboframe?do=AMLList`;
        frame.setAttribute("src", url);



        var genericExamples = document.querySelectorAll('[data-trigger]');
        for (let i = 0; i < genericExamples.length; ++i) {
            var element = genericExamples[i];
            new Choices(element, {
                placeholderValue: 'This is a placeholder set in the config',
                searchPlaceholderValue: 'Search',
            });
        }

        $('.aml_data_sources').on('click', function () {

            var frame = document.querySelector("#AMLContent");
            var url = `aml/turboframe?do=AMLDataSource`;
            frame.setAttribute("src", url);
        });



        const entityTypeSelect = document.getElementById('entity_type');
        const allEntityFields = document.querySelectorAll('.entity-fields');

        // Function to show/hide fields based on selected entity type
        function toggleEntityFields() {
            const selectedType = entityTypeSelect.value;

            // Hide all field sets first
            allEntityFields.forEach(fieldset => {
                fieldset.classList.add('d-none');

                // Disable all required fields in hidden sections
                fieldset.querySelectorAll('[required]').forEach(field => {
                    field.disabled = true;
                });
            });

            // Show selected field set
            const selectedFields = document.getElementById(`${selectedType.toLowerCase()}-fields`);
            if (selectedFields) {
                selectedFields.classList.remove('d-none');

                // Enable required fields in visible section
                selectedFields.querySelectorAll('[required]').forEach(field => {
                    field.disabled = false;
                });
            }
        }

        // Initialize form validation
        function initializeValidation() {
            const form = document.querySelector('.needs-validation');

            form.addEventListener('submit', function(event) {
                if (!form.checkValidity()) {
                    event.preventDefault();
                    event.stopPropagation();
                }

                form.classList.add('was-validated');
            }, false);
        }

        // Date validation for specific fields
        function initializeDateValidation() {
            const dateFields = document.querySelectorAll('input[data-type="date"]');

            dateFields.forEach(field => {
                field.addEventListener('input', function(e) {
                    const value = e.target.value;
                    const datePattern = /^\d{4}(-\d{2}(-\d{2})?)?$/;

                    if (!datePattern.test(value)) {
                        field.setCustomValidity('Please enter a date in YYYY, YYYY-MM, or YYYY-MM-DD format');
                    } else {
                        field.setCustomValidity('');
                    }
                });
            });
        }

        // Initialize all event listeners
        entityTypeSelect.addEventListener('change', toggleEntityFields);
        initializeValidation();
        initializeDateValidation();

        // Initial toggle on page load
        toggleEntityFields();


        if (this.element.dataset.autoLoad === 'true') {
            const profileId = this.element.dataset.profileId;
            this.loadProfile(profileId);
        }


    }

    loadProfile(profile_id) {
        $('#hotLoader').modal('show');
        var frame = document.querySelector("#AMLContent");
        var url = `aml/${profile_id}?do=entity`;
        frame.setAttribute("src", url);
        $('#hotLoader').modal('hide');
    }


}