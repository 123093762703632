import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "detailsForm",
        "verificationForm",
        "firstName",
        "lastName",
        "phoneNumber",
        "phoneDisplay",
        "verificationCode"
    ]

    connect() {

        this.modal = new bootstrap.Modal(this.element)
    }

    sendCode(event) {
        event.preventDefault()

        // Here you would make an API call to send the verification code
        console.log("Sending code to:", this.phoneNumberTarget.value)

        // Update phone display
        this.phoneDisplayTarget.textContent = `We've sent a code to ${this.phoneNumberTarget.value}`

        // Switch to verification form
        this.detailsFormTarget.classList.add('d-none')
        this.verificationFormTarget.classList.remove('d-none')
    }

    verifyCode(event) {
        event.preventDefault()

        // Here you would verify the code with your backend
        console.log("Verifying code:", this.verificationCodeTarget.value)

        // Reset and close modal on success
        this.reset()
        this.modal.hide()
    }

    resendCode(event) {
        event.preventDefault()
        console.log("Resending code to:", this.phoneNumberTarget.value)
    }

    reset() {
        // Reset forms and switch back to details view
        this.detailsFormTarget.reset()
        this.verificationFormTarget.reset()
        this.detailsFormTarget.classList.remove('d-none')
        this.verificationFormTarget.classList.add('d-none')
    }
}