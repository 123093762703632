import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["description", "permissions", "form", "roleSelect", "userName", "userToken", "currentRole", "userTitle"]
    static values = {
        roles: Object
    }

    connect() {
        const modal = this.element.closest('.modal')
        if (modal) {
            modal.addEventListener('show.bs.modal', this.handleModalShow.bind(this))
            modal.addEventListener('shown.bs.modal', this.handleModalShown.bind(this))
        }
    }

    handleModalShow(event) {
        const button = event.relatedTarget
        const name = button.dataset.name

        // Get the data from the button
        const role = button.dataset.role
        const token = button.dataset.token

        if (this.hasUserTitleTarget) {
            this.userTitleTarget.textContent = `Change role for ${name}`
        }


        // Update hidden fields
        if (this.hasUserNameTarget) {
            this.userNameTarget.value = name
        }
        if (this.hasUserTokenTarget) {
            this.userTokenTarget.value = token
        }
        if (this.hasCurrentRoleTarget) {
            this.currentRoleTarget.value = role
        }

        // Set the selected role
        if (this.hasRoleSelectTarget) {
            this.roleSelectTarget.value = role
        }
    }

    handleModalShown() {
        if (this.hasRoleSelectTarget) {
            const selectedRole = this.roleSelectTarget.value.toLowerCase()
            this.updateRole(selectedRole)
        }
    }



    handleModalShown() {
        // Load initial role description after modal is fully shown
        if (this.hasRoleSelectTarget) {
            const selectedRole = this.roleSelectTarget.value.toLowerCase()
            this.updateRole(selectedRole)
        }
    }

    change(event) {
        const selectedRole = event.target.value.toLowerCase()
        this.updateRole(selectedRole)
    }

    updateRole(role) {
        if (!this.hasDescriptionTarget || !this.hasPermissionsTarget) {
            console.error("Missing required targets")
            return
        }

        try {
            const roleInfo = this.rolesValue[role]
            if (roleInfo) {
                // Update description
                this.descriptionTarget.querySelector('.description-text').textContent =
                    roleInfo.description || 'No description available'

                // Update permissions
                const permissionsContainer = this.permissionsTarget
                permissionsContainer.innerHTML = ''

                if (roleInfo.permissions && roleInfo.permissions.length > 0) {
                    roleInfo.permissions.forEach(permission => {
                        const tag = document.createElement('span')
                        tag.className = 'permission-tag'
                        tag.textContent = permission.replace('_', ' ')
                        permissionsContainer.appendChild(tag)
                    })
                } else {
                    permissionsContainer.innerHTML = '<span class="text-gray-500">No special permissions</span>'
                }
            } else {
                this.descriptionTarget.querySelector('.description-text').textContent =
                    'No description available'
                this.permissionsTarget.innerHTML = ''
            }
        } catch (error) {
            console.error("Error updating role info:", error)
        }
    }
}