import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    tableConfig: {
      type: Object,
      default: {
        '#id_table': {
          autoWidth: true,
          columns: [
            {
              data: 'document_type',
              render: (data, type, row) => data || "ANY"
            },
            {
              data: 'country',
              render: (data, type, row) => data || "ANY"
            },
            {
              data: 'document_number',
              render: (data, type, row) => data || "ANY"
            },
            {
              data: 'first_names',
              render: (data, type, row) => data || "ANY"
            },
            {
              data: 'last_names',
              render: (data, type, row) => data || "ANY"
            },
            { data: 'list_type' },      // List Type
            { data: 'expires' },        // Expires
            { data: 'actions', orderable: false }  // Actions
          ],
          order: [[0, 'asc']],
          pageLength: 10,
          processing: true,
          language: {
            emptyTable: "No dataset entries"
          }
        },
        '#datasharing': {
          autoWidth: true,
          columns: [
            { data: 'account_number' },        // Account Number
            { data: 'name' },                  // Name
            { data: 'org_jurisdiction' },      // Organization Jurisdiction
            { data: 'storage_jurisdiction' },  // Storage Jurisdiction
            { data: 'direction' },            // Direction
            { data: 'actions', orderable: false }  // Actions
          ],
          order: [[0, 'asc']],
          pageLength: 10,
          processing: true,
          language: {
            emptyTable: "No dataset entries"
          }
        }
      }
    }
  }

  initialize() {
    this.initializedTables = new Set();

    // Bind the method to handle Turbo cache
    this.handleTurboCache = this.handleTurboCache.bind(this);
    document.addEventListener('turbo:before-cache', this.handleTurboCache);
  }

  connect() {
    // Wait for the next frame to ensure DOM is ready
    requestAnimationFrame(() => {
      this.initializeTables();
      this.initializePopovers();
    });
  }

  handleTurboCache() {
    // Cleanup tables before page is cached
    this.cleanup();
  }

  initializeTables() {
    const configs = this.tableConfigValue;

    Object.entries(configs).forEach(([tableId, config]) => {
      const table = document.querySelector(`table${tableId}`);

      if (table) {
        // Check if table is already initialized
        if (this.isTableInitialized(table)) {
          console.log(`Table ${tableId} already initialized, destroying first`);
          this.destroyTable(table);
        }

        // Add default configuration options
        const finalConfig = {
          ...config,
          retrieve: true, // Allows reinitialization
          destroy: true,  // Ensures clean destruction
          responsive: true,
          dom: 'lBfrtip',  // Adds length, buttons, filter, processing, table, information, pagination
        };

        console.log(`Initializing table: ${tableId}`);
        new DataTable(table, finalConfig);
        this.initializedTables.add(tableId);
      }
    });
  }

  isTableInitialized(table) {
    try {
      return $.fn.DataTable.isDataTable(table);
    } catch (e) {
      return false;
    }
  }

  destroyTable(table) {
    try {
      const dt = $(table).DataTable();
      if (dt) {
        dt.destroy();
        $(table).find('tbody').empty();
      }
    } catch (e) {
      console.error('Error destroying DataTable:', e);
    }
  }

  initializePopovers() {
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    popoverTriggerList.forEach(popoverTriggerEl => {
      new bootstrap.Popover(popoverTriggerEl);
    });
  }

  cleanup() {
    this.initializedTables.forEach(tableId => {
      const table = document.querySelector(`table${tableId}`);
      if (table) {
        this.destroyTable(table);
      }
    });
    this.initializedTables.clear();
  }

  disconnect() {
    // Remove the Turbo cache handler
    document.removeEventListener('turbo:before-cache', this.handleTurboCache);
    this.cleanup();
  }
}