import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {



        const profileId = this.element.dataset.profileId;
        $('#hotLoader').modal('show');
        var frame = document.querySelector("#AMLContent");
        var url = `aml/${profileId}?do=entity`;
        frame.setAttribute("src", url);
        $('#hotLoader').modal('hide');
    }





}