// app/javascript/controllers/flash_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        const flashMessages = this.element.querySelectorAll('script[type="text/javascript"]')

        flashMessages.forEach(script => {
            // Execute the script content safely
            const content = script.textContent.trim()
            if (content) {
                try {
                    eval(content)
                } catch (e) {
                    console.error('Error showing flash message:', e)
                }
            }
        })
    }

    // Method to show toast from Turbo Stream
    show({ detail: { message, type = 'success', submessage = null } }) {
        const status = type.replace('alert', 'error').replace('notice', 'success')

        toast.show({
            status: status,
            message: message,
            subMessage: submessage
        })
    }
}